<template>
  <div class="category-management">
    <header>
      <h1>分类管理</h1>
      <button class="primary-btn" @click="openAddModal">添加分类</button>
    </header>

    <!-- 分类列表表格 -->
    <div class="table-responsive">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>分类名称</th>
            <th>分类标识</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="categories.length === 0">
            <td colspan="4">暂无分类数据</td>
          </tr>
          <tr v-else v-for="category in categories" :key="category.id">
            <td data-label="ID">{{ category.id }}</td>
            <td data-label="分类名称">{{ category.name }}</td>
            <td data-label="分类标识">{{ category.category }}</td>
            <td data-label="操作">
              <button class="edit-btn" @click="openEditModal(category)">编辑</button>
              <button class="delete-btn" @click="deleteCategory(category.id)">删除</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 添加/编辑分类的模态框 -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>{{ isEdit ? '编辑分类' : '添加分类' }}</h2>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="name">分类名称：</label>
            <input id="name" v-model="formData.name" required />
          </div>
          <div class="form-group">
            <label for="category">分类标识：</label>
            <input id="category" v-model="formData.category" required />
          </div>
          <div class="modal-actions">
            <button type="submit" class="submit-btn">{{ isEdit ? '更新' : '添加' }}</button>
            <button type="button" class="cancel-btn" @click="closeModal">取消</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'; // 确保已安装 axios，并正确配置了 baseURL

export default {
  name: 'CategoryManagement',
  data() {
    return {
      categories: [], // 分类列表
      showModal: false, // 控制模态框显示
      isEdit: false, // 区分添加或编辑模式
      formData: {
        id: null,
        name: '',
        category: '',
      },
    };
  },
  methods: {
    // 获取分类列表
    fetchCategories() {
      axios
        .get('/api/category/list')
        .then((response) => {
          if (response.data.code === '200') {
            this.categories = response.data.data;
          } else {
            this.showAlert('获取分类列表失败：' + response.data.message);
          }
        })
        .catch((error) => {
          console.error('请求错误：', error);
          this.showAlert('请求失败，请稍后重试。');
        });
    },
    // 打开添加模态框
    openAddModal() {
      this.isEdit = false;
      this.formData = {
        id: null,
        name: '',
        category: '',
      };
      this.showModal = true;
    },
    // 打开编辑模态框
    openEditModal(category) {
      this.isEdit = true;
      this.formData = { ...category };
      this.showModal = true;
    },
    // 关闭模态框
    closeModal() {
      this.showModal = false;
    },
    // 提交表单
    submitForm() {
      if (this.isEdit) {
        axios
          .put('/api/category/update', this.formData)
          .then((response) => {
            if (response.data.code === '200') {
              this.showAlert('更新成功');
              this.fetchCategories();
              this.closeModal();
            } else {
              this.showAlert('更新失败：' + response.data.message);
            }
          })
          .catch((error) => {
            console.error('更新请求错误：', error);
            this.showAlert('更新失败，请稍后重试。');
          });
      } else {
        axios
          .post('/api/category/add', this.formData)
          .then((response) => {
            if (response.data.code === '200') {
              this.showAlert('添加成功');
              this.fetchCategories();
              this.closeModal();
            } else {
              this.showAlert('添加失败：' + response.data.message);
            }
          })
          .catch((error) => {
            console.error('添加请求错误：', error);
            this.showAlert('添加失败，请稍后重试。');
          });
      }
    },
    // 删除分类
    deleteCategory(id) {
      if (confirm('确认删除该分类吗？')) {
        axios
          .delete(`/api/category/${id}`)
          .then((response) => {
            if (response.data.code === '200') {
              this.showAlert('删除成功');
              this.fetchCategories();
            } else {
              this.showAlert('删除失败：' + response.data.message);
            }
          })
          .catch((error) => {
            console.error('删除请求错误：', error);
            this.showAlert('删除失败，请稍后重试。');
          });
      }
    },
    // 显示提示信息
    showAlert(message) {
      alert(message);
    },
  },
  mounted() {
    this.fetchCategories();
  },
};
</script>
<style scoped>
/* 全局字体和背景 */
.category-management {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f9f9f9;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

/* 头部样式 */
header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

header h1 {
  margin-bottom: 10px;
  font-size: 2em;
  color: #333;
}

.primary-btn {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.primary-btn:hover {
  background-color: #218838;
}

/* 表格容器 */
.table-responsive {
  overflow-x: auto;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* 表格样式 */
table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 12px 15px;
  text-align: center;
}

table th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: 600;
}

table tr:nth-child(even) {
  background-color: #fafafa;
}

table tr:hover {
  background-color: #f1f1f1;
}

table td img {
  max-width: 80px;
  height: auto;
  border-radius: 4px;
}

/* 按钮样式 */
button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}

.edit-btn {
  background-color: #007bff;
  color: #fff;
  margin-right: 8px;
}

.edit-btn:hover {
  background-color: #0056b3;
}

.delete-btn {
  background-color: #dc3545;
  color: #fff;
}

.delete-btn:hover {
  background-color: #c82333;
}

/* 模态框样式 */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  width: 90%;
  max-width: 500px;
  padding: 25px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 1.5em;
  color: #aaa;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close:hover {
  color: #000;
}

.modal-content h2 {
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: 600;
  color: #555;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.submit-btn {
  background-color: #28a745;
  color: #fff;
  margin-right: 10px;
}

.submit-btn:hover {
  background-color: #218838;
}

.cancel-btn {
  background-color: #6c757d;
  color: #fff;
}

.cancel-btn:hover {
  background-color: #5a6268;
}

/* 响应式设计 */
@media (max-width: 1024px) {
  header {
    flex-direction: column;
    align-items: flex-start;
  }

  header h1 {
    font-size: 1.8em;
  }

  .primary-btn {
    align-self: center;
  }

  .modal-content {
    padding: 20px;
  }

  table th,
  table td {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .category-management {
    padding: 10px;
  }

  header h1 {
    font-size: 1.5em;
  }

  .primary-btn {
    width: 100%;
    margin-top: 10px;
  }

  table {
    min-width: 500px;
  }

  .modal-content {
    width: 95%;
  }

  .form-group {
    flex-direction: column;
    align-items: stretch;
  }

  .form-group label {
    text-align: left;
  }

  .modal-actions {
    flex-direction: column;
    align-items: stretch;
  }

  .submit-btn,
  .cancel-btn {
    width: 100%;
    margin: 5px 0;
  }
}

@media (max-width: 480px) {
  .modal-content {
    padding: 15px;
    max-width: none;
    height: 100%;
    border-radius: 0;
  }

  table {
    min-width: 100%;
    font-size: 0.9em;
  }

  table th,
  table td {
    padding: 8px;
  }

  header h1 {
    font-size: 1.2em;
  }

  .form-group input {
    font-size: 0.9em;
  }

  button {
    font-size: 0.8em;
  }
}
</style>
