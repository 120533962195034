// src/router/index.js
import { createRouter, createWebHashHistory } from 'vue-router';
import ScenicSpotList from '@/components/ScenicSpotList.vue';
import AudioList from '@/components/AudioList.vue';
import CategoryList from '@/components/CategoryList';

const routes = [
  {
    path: '/scenicSpots',
    name: 'ScenicSpotList',
    component: ScenicSpotList,
  },
  {
    path: '/audios',
    name: 'AudioList',
    component: AudioList,
  },
  {
    path: '/category',
    name: 'CategoryList',
    component: CategoryList,
  },
  {
    path: '/',
    redirect: '/scenicSpots',
  },
];

const router = createRouter({
  history: createWebHashHistory(), // 使用 Hash 模式
  routes,
});

export default router;
