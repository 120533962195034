<template>
  <div id="app">
    <div class="container">
      <!-- 在移动设备上，添加一个菜单按钮 -->
      <button class="menu-toggle" @click="toggleSideNav" v-if="isMobile">☰</button>
      <!-- 侧边栏 -->
      <SideNav :is-visible="!isMobile || showSideNav" @close="closeSideNav" />
      <!-- 主内容 -->
      <div class="main-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import SideNav from '@/components/SideNav.vue';

export default {
  components: {
    SideNav,
  },
  setup() {
    const isMobile = ref(false);
    const showSideNav = ref(false);

    const checkScreenWidth = () => {
      isMobile.value = window.innerWidth <= 768;
      if (!isMobile.value) {
        showSideNav.value = false;
      }
    };

    const toggleSideNav = () => {
      showSideNav.value = !showSideNav.value;
    };

    const closeSideNav = () => {
      showSideNav.value = false;
    };

    onMounted(() => {
      checkScreenWidth();
      window.addEventListener('resize', checkScreenWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', checkScreenWidth);
    });

    return {
      isMobile,
      showSideNav,
      toggleSideNav,
      closeSideNav,
    };
  },
};
</script>

<style>
/* 全局样式 */
.container {
  display: flex;
}

/* 移动设备上隐藏侧边栏 */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
}

/* 菜单按钮 */
.menu-toggle {
  position: fixed;
  top: 10px;
  left: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 20px;
  cursor: pointer;
  z-index: 1000;
}

/* 主内容 */
.main-content {
  flex: 1;
  padding: 20px;
}
</style>
