<template>
  <div class="scenic-spot-list">
    <h1>景区管理</h1>
    <!-- 添加景区按钮 -->
    <button class="primary-btn" @click="openAddModal">添加景区</button>

    <!-- 景区列表表格 -->
    <div class="table-responsive">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>景区名称</th>
            <th>景区图片</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="scenicSpots.length === 0">
            <td colspan="4">暂无景区数据</td>
          </tr>
          <tr v-else v-for="spot in scenicSpots" :key="spot.scenicSpotId">
            <td data-label="ID">{{ spot.scenicSpotId }}</td>
            <td data-label="景区名称">{{ spot.scenicSpotName }}</td>
            <td data-label="景区图片">
              <img :src="spot.scenicSpotUrl" alt="景区图片" class="thumbnail" />
            </td>
            <td data-label="操作">
              <button class="edit-btn" @click="openEditModal(spot)">编辑</button>
              <button class="delete-btn" @click="deleteScenicSpot(spot.scenicSpotId)">删除</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 添加/编辑景区的模态框 -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>{{ isEdit ? '编辑景区' : '添加景区' }}</h2>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label>景区名称：</label>
            <input v-model="formData.scenicSpotName" required />
          </div>
          <div class="form-group">
            <label>上传景区图片：</label>
            <input
              type="file"
              @change="handleFileChange"
              accept="image/*"
              :required="!isEdit"
            />
          </div>
          <div v-if="imagePreview" class="form-group">
            <label>图片预览：</label>
            <img :src="imagePreview" alt="图片预览" class="thumbnail-preview" />
          </div>
          <div class="modal-actions">
            <button type="submit" class="submit-btn">{{ isEdit ? '更新' : '添加' }}</button>
            <button type="button" class="cancel-btn" @click="closeModal">取消</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
  name: 'ScenicSpotList',
  setup() {
    const scenicSpots = ref([]);
    const showModal = ref(false);
    const isEdit = ref(false);
    const formData = ref({
      scenicSpotId: null,
      scenicSpotName: '',
      scenicSpotUrl: '',
    });
    const imagePreview = ref('');
    const selectedFile = ref(null);

    // 获取景区列表
    const fetchScenicSpots = () => {
      axios
        .get('/api/scenic-spot/list')
        .then((response) => {
          if (response.data.code === '200') {
            scenicSpots.value = response.data.data;
          } else {
            alert('获取景区列表失败：' + response.data.message);
          }
        })
        .catch((error) => {
          console.error('请求错误：', error);
        });
    };

    // 打开添加模态框
    const openAddModal = () => {
      isEdit.value = false;
      formData.value = {
        scenicSpotId: null,
        scenicSpotName: '',
        scenicSpotUrl: '',
      };
      selectedFile.value = null;
      imagePreview.value = '';
      showModal.value = true;
    };

    // 打开编辑模态框
    const openEditModal = (spot) => {
      isEdit.value = true;
      formData.value = { ...spot };
      selectedFile.value = null;
      imagePreview.value = spot.scenicSpotUrl;
      showModal.value = true;
    };

    // 关闭模态框
    const closeModal = () => {
      showModal.value = false;
      selectedFile.value = null;
      imagePreview.value = '';
    };

    // 处理图片文件选择
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        selectedFile.value = file;
        imagePreview.value = URL.createObjectURL(file);
      } else {
        selectedFile.value = null;
        imagePreview.value = '';
      }
    };

    // 提交表单
    const submitForm = async () => {
      try {
        if (!selectedFile.value && !isEdit.value) {
          alert('请上传景区图片');
          return;
        }

        if (selectedFile.value) {
          const fileData = new FormData();
          fileData.append('file', selectedFile.value);

          const uploadResponse = await axios.post('/api/file/upload', fileData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (!uploadResponse.data.startsWith('http')) {
            alert('图片上传失败：' + uploadResponse.data);
            return;
          }

          formData.value.scenicSpotUrl = uploadResponse.data;
        }

        // 提交景区信息
        if (isEdit.value) {
          const response = await axios.put('/api/scenic-spot/update', formData.value);
          if (response.data.code === '200') {
            alert('更新成功');
            fetchScenicSpots();
            closeModal();
          } else {
            alert('更新失败：' + response.data.message);
          }
        } else {
          const response = await axios.post('/api/scenic-spot/add', formData.value);
          if (response.data.code === '200') {
            alert('添加成功');
            fetchScenicSpots();
            closeModal();
          } else {
            alert('添加失败：' + response.data.message);
          }
        }
      } catch (error) {
        console.error('提交请求错误：', error);
        alert('提交失败，请检查网络连接或联系管理员');
      }
    };

    // 删除景区
    const deleteScenicSpot = (id) => {
      if (confirm('确认删除该景区吗？')) {
        axios
          .delete(`/api/scenic-spot/${id}`)
          .then((response) => {
            if (response.data.code === '200') {
              alert('删除成功');
              fetchScenicSpots();
            } else {
              alert('删除失败：' + response.data.message);
            }
          })
          .catch((error) => {
            console.error('删除请求错误：', error);
          });
      }
    };

    onMounted(() => {
      fetchScenicSpots();
    });

    return {
      scenicSpots,
      showModal,
      isEdit,
      formData,
      imagePreview,
      selectedFile,
      fetchScenicSpots,
      openAddModal,
      openEditModal,
      closeModal,
      handleFileChange,
      submitForm,
      deleteScenicSpot,
    };
  },
};
</script>

<style scoped>
.scenic-spot-list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.primary-btn {
  background-color: #2d89ef;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.primary-btn:hover {
  background-color: #1b6ec2;
}

/* 表格样式 */
.table-responsive {
  overflow-x: auto;
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  min-width: 300px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

thead {
  background-color: #f0f0f0;
}

th,
td {
  border: 1px solid #ddd;
  padding: 12px 15px;
  text-align: center;
}

th {
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

img.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

/* 按钮样式 */
.edit-btn,
.delete-btn {
  padding: 6px 12px;
  margin: 5px 5px 5px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
}

.edit-btn {
  background-color: #f0ad4e;
}

.edit-btn:hover {
  background-color: #ec971f;
}

.delete-btn {
  background-color: #d9534f;
}

.delete-btn:hover {
  background-color: #c9302c;
}

/* 模态框样式 */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  width: 90%;
  max-width: 600px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 24px;
  cursor: pointer;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
}

.form-group input[type='text'],
.form-group input[type='file'] {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group img.thumbnail-preview {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-top: 10px;
  border-radius: 4px;
}

.modal-actions {
  text-align: right;
}

.submit-btn,
.cancel-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  margin-left: 10px;
}

.submit-btn {
  background-color: #28a745;
}

.submit-btn:hover {
  background-color: #218838;
}

.cancel-btn {
  background-color: #6c757d;
}

.cancel-btn:hover {
  background-color: #5a6268;
}

/* 响应式处理 */
@media (max-width: 768px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    background-color: #fff;
  }

  td {
    border: none;
    position: relative;
    padding-left: 50%;
    text-align: left;
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 15px;
    width: calc(50% - 30px);
    white-space: nowrap;
    font-weight: bold;
  }

  td img {
    max-width: 100px;
    height: auto;
  }

  .modal-content {
    width: 95%;
    padding: 20px;
  }
}
</style>
