<template>
  <div
    class="side-nav"
    :class="{ 'side-nav--visible': isVisible }"
    @click.self="closeSideNav"
  >
    <div class="side-nav__content">
      <!-- 关闭按钮（移动设备） -->
      <button class="close-btn" @click="closeSideNav" v-if="isMobile">✕</button>
      
      <!-- 新增的标题 -->
      <div class="side-nav__header">景点管理系统</div>
      
      <ul>
        <li :class="{ active: isActive('/scenicSpots') }">
          <router-link to="/scenicSpots">景区管理</router-link>
        </li>
        <li :class="{ active: isActive('/audios') }">
          <router-link to="/audios">音频管理</router-link>
        </li>
        <li :class="{ active: isActive('/category') }">
          <router-link to="/category">分类管理</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, onUnmounted } from 'vue'; // 移除了未使用的 computed
import { useRoute } from 'vue-router';

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const route = useRoute();

    const isMobile = ref(false);

    const checkScreenWidth = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    const closeSideNav = () => {
      emit('close');
    };

    const isActive = (path) => {
      return route.path === path;
    };

    watch(
      () => route.path,
      () => {
        // 路由变化时，关闭侧边栏（移动设备）
        if (isMobile.value && props.isVisible) {
          closeSideNav();
        }
      }
    );

    onMounted(() => {
      checkScreenWidth();
      window.addEventListener('resize', checkScreenWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', checkScreenWidth);
    });

    return {
      isMobile,
      isActive,
      closeSideNav,
    };
  },
};
</script>

<style scoped>
.side-nav {
  width: 200px;
  background-color: #2c3e50;
  min-height: 100vh;
  color: #ecf0f1;
  position: relative;
}

/* 移动设备上的抽屉效果 */
@media (max-width: 768px) {
  .side-nav {
    position: fixed;
    top: 0;
    left: -200px;
    height: 100%;
    transition: left 0.3s ease;
    z-index: 999;
  }
  .side-nav.side-nav--visible {
    left: 0;
  }
  .side-nav__content {
    position: relative;
    width: 200px;
    height: 100%;
    background-color: #2c3e50;
    overflow-y: auto;
  }
  /* 背景遮罩 */
  .side-nav.side-nav--visible::after {
    content: '';
    position: fixed;
    top: 0;
    left: 200px;
    width: calc(100% - 200px);
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
}

/* 关闭按钮 */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #ecf0f1;
  font-size: 24px;
  cursor: pointer;
}

.side-nav__header {
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #34495e;
}

.side-nav ul {
  list-style: none;
  padding: 20px 0;
  margin: 0;
}

.side-nav li {
  padding: 15px 20px;
}

.side-nav li.active {
  background-color: #34495e;
}

.side-nav a {
  text-decoration: none;
  color: #ecf0f1;
}

.side-nav a:hover {
  color: #fff;
}
</style>
