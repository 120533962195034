<template>
  <div class="audio-list">
    <h1>音频管理</h1>
    <!-- 添加音频按钮 -->
    <button class="primary-btn" @click="openAddModal">添加音频</button>

    <!-- 音频列表表格 -->
    <div class="table-responsive">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>音频URL</th>
            <th>类别</th>
            <th>图片</th>
            <th>景区板块</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="audios.length === 0">
            <td colspan="6">暂无音频数据</td>
          </tr>
          <tr v-else v-for="audio in audios" :key="audio.id">
            <td data-label="ID">{{ audio.id }}</td>
            <td data-label="音频URL">
              <a :href="audio.audioUrl" target="_blank">{{ audio.audioUrl }}</a>
            </td>
            <td data-label="类别">{{ getCategoryName(audio.category) }}</td>
            <td data-label="图片">
              <img :src="audio.imageUrl" alt="音频图片" />
            </td>
            <td data-label="景区板块">{{ getScenicSpotName(audio.scenicSpotId) }}</td>
            <td data-label="操作">
              <button class="edit-btn" @click="openEditModal(audio)">编辑</button>
              <button class="delete-btn" @click="deleteAudio(audio.id)">删除</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 添加/编辑音频的模态框 -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>{{ isEdit ? '编辑音频' : '添加音频' }}</h2>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label>上传音频：</label>
            <input
              type="file"
              @change="handleAudioFileChange"
              accept="audio/*"
              :required="!isEdit"
            />
          </div>
          <div class="form-group">
            <label>类别：</label>
            <select v-model="formData.category" required>
              <option value="" disabled>请选择类别</option>
              <option
                v-for="category in categories"
                :key="category.id"
                :value="category.category"
              >
                {{ category.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>上传图片：</label>
            <input
              type="file"
              @change="handleFileChange"
              accept="image/*"
              :required="!isEdit"
            />
          </div>
          <div v-if="imagePreview" class="form-group">
            <label>图片预览：</label>
            <img :src="imagePreview" alt="图片预览" />
          </div>
          <div class="form-group">
            <label>所属景区：</label>
            <select v-model="formData.scenicSpotId" required>
              <option value="" disabled>请选择景区</option>
              <option
                v-for="spot in scenicSpots"
                :key="spot.scenicSpotId"
                :value="spot.scenicSpotId"
              >
                {{ spot.scenicSpotName }}
              </option>
            </select>
          </div>
          <div class="modal-actions">
            <button type="submit" class="submit-btn">
              {{ isEdit ? '更新' : '添加' }}
            </button>
            <button type="button" class="cancel-btn" @click="closeModal">
              取消
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
  name: 'AudioList',
  setup() {
    const audios = ref([]);
    const scenicSpots = ref([]);
    const categories = ref([]); // 分类列表
    const showModal = ref(false);
    const isEdit = ref(false);
    const formData = ref({
      id: null,
      audioUrl: '',
      category: '', // 存储选中的类别
      imageUrl: '',
      scenicSpotId: '',
    });
    const selectedAudioFile = ref(null); // 选中的音频文件
    const selectedFile = ref(null); // 选中的图片文件
    const imagePreview = ref(''); // 图片预览

    // 获取音频列表
    const fetchAudios = () => {
      axios
        .get('/api/audio/list')
        .then((response) => {
          if (response.data.code === '200') {
            audios.value = response.data.data;
          } else {
            alert('获取音频列表失败：' + response.data.message);
          }
        })
        .catch((error) => {
          console.error('请求错误：', error);
        });
    };

    // 获取景区列表
    const fetchScenicSpots = () => {
      axios
        .get('/api/scenic-spot/list')
        .then((response) => {
          if (response.data.code === '200') {
            scenicSpots.value = response.data.data;
          } else {
            alert('获取景区列表失败：' + response.data.message);
          }
        })
        .catch((error) => {
          console.error('请求错误：', error);
        });
    };

    // 获取分类列表
    const fetchCategories = () => {
      axios
        .get('/api/category/list')
        .then((response) => {
          if (response.data.code === '200') {
            categories.value = response.data.data;
          } else {
            alert('获取分类列表失败：' + response.data.message);
          }
        })
        .catch((error) => {
          console.error('请求错误：', error);
        });
    };

    // 根据分类ID获取分类名称
    const getCategoryName = (categoryId) => {
      const category = categories.value.find(
        (item) => item.category === categoryId
      );
      return category ? category.name : '未知类别';
    };

    // 根据景区ID获取景区名称
    const getScenicSpotName = (id) => {
      const spot = scenicSpots.value.find(
        (item) => item.scenicSpotId === id
      );
      return spot ? spot.scenicSpotName : '未关联景区';
    };

    // 打开添加模态框
    const openAddModal = () => {
      isEdit.value = false;
      formData.value = {
        id: null,
        audioUrl: '',
        category: '',
        imageUrl: '',
        scenicSpotId: '',
      };
      selectedFile.value = null;
      selectedAudioFile.value = null;
      imagePreview.value = '';
      showModal.value = true;
    };

    // 打开编辑模态框
    const openEditModal = (audio) => {
      isEdit.value = true;
      formData.value = { ...audio };
      selectedFile.value = null;
      selectedAudioFile.value = null;
      imagePreview.value = audio.imageUrl;
      showModal.value = true;
    };

    // 关闭模态框
    const closeModal = () => {
      showModal.value = false;
      selectedFile.value = null;
      selectedAudioFile.value = null;
      imagePreview.value = '';
    };

    // 处理音频文件选择
    const handleAudioFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        selectedAudioFile.value = file;
      } else {
        selectedAudioFile.value = null;
      }
    };

    // 处理图片文件选择
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        selectedFile.value = file;
        imagePreview.value = URL.createObjectURL(file);
      } else {
        selectedFile.value = null;
        imagePreview.value = '';
      }
    };

    // 提交表单
    const submitForm = async () => {
      try {
        // 检查是否需要上传音频和图片文件
        if (!selectedAudioFile.value && !isEdit.value) {
          alert('请上传音频文件');
          return;
        }
        if (!selectedFile.value && !isEdit.value) {
          alert('请上传音频图片');
          return;
        }

        // 上传音频文件
        if (selectedAudioFile.value) {
          const audioFormData = new FormData();
          audioFormData.append('file', selectedAudioFile.value);

          const audioUploadResponse = await axios.post(
            '/api/file/upload',
            audioFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          if (!audioUploadResponse.data.startsWith('http')) {
            alert('音频文件上传失败：' + audioUploadResponse.data);
            return;
          }

          // 将返回的音频 URL 设置到 formData.audioUrl
          formData.value.audioUrl = audioUploadResponse.data;
        }

        // 上传图片文件
        if (selectedFile.value) {
          const imageFormData = new FormData();
          imageFormData.append('file', selectedFile.value);

          const imageUploadResponse = await axios.post(
            '/api/file/upload',
            imageFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          if (!imageUploadResponse.data.startsWith('http')) {
            alert('图片上传失败：' + imageUploadResponse.data);
            return;
          }

          // 将返回的图片 URL 设置到 formData.imageUrl
          formData.value.imageUrl = imageUploadResponse.data;
        }

        // 提交音频信息
        if (isEdit.value) {
          const response = await axios.put('/api/audio/update', formData.value);
          if (response.data.code === '200') {
            alert('更新成功');
            fetchAudios();
            closeModal();
          } else {
            alert('更新失败：' + response.data.message);
          }
        } else {
          const response = await axios.post('/api/audio/add', formData.value);
          if (response.data.code === '200') {
            alert('添加成功');
            fetchAudios();
            closeModal();
          } else {
            alert('添加失败：' + response.data.message);
          }
        }
      } catch (error) {
        console.error('提交请求错误：', error);
        alert('提交失败，请检查网络连接或联系管理员');
      }
    };

    // 删除音频
    const deleteAudio = (id) => {
      if (confirm('确认删除该音频吗？')) {
        axios
          .delete(`/api/audio/${id}`)
          .then((response) => {
            if (response.data.code === '200') {
              alert('删除成功');
              fetchAudios();
            } else {
              alert('删除失败：' + response.data.message);
            }
          })
          .catch((error) => {
            console.error('删除请求错误：', error);
          });
      }
    };

    onMounted(() => {
      fetchAudios();
      fetchScenicSpots();
      fetchCategories(); // 获取分类列表
    });

    return {
      audios,
      scenicSpots,
      categories,
      showModal,
      isEdit,
      formData,
      selectedAudioFile,
      selectedFile,
      imagePreview,
      fetchAudios,
      fetchScenicSpots,
      fetchCategories,
      openAddModal,
      openEditModal,
      closeModal,
      handleAudioFileChange,
      handleFileChange,
      submitForm,
      deleteAudio,
      getCategoryName,
      getScenicSpotName,
    };
  },
};
</script>

<style scoped>
.audio-list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.primary-btn {
  background-color: #2d89ef;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.primary-btn:hover {
  background-color: #1b6ec2;
}

/* 表格样式 */
.table-responsive {
  overflow-x: auto;
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  min-width: 300px; /* 确保表格有最小宽度，便于滚动 */
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

thead {
  background-color: #f0f0f0;
}

th,
td {
  border: 1px solid #ddd;
  padding: 12px 15px;
  text-align: center;
}

th {
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

img {
  max-width: 100px;
  height: auto;
  border-radius: 4px;
}

/* 按钮样式 */
.edit-btn,
.delete-btn {
  padding: 6px 12px;
  margin: 5px 5px 5px 0; /* 上右下左 */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
}

.edit-btn {
  background-color: #f0ad4e;
}

.edit-btn:hover {
  background-color: #ec971f;
}

.delete-btn {
  background-color: #d9534f;
}

.delete-btn:hover {
  background-color: #c9302c;
}

/* 模态框样式 */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  width: 90%;
  max-width: 600px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 24px;
  cursor: pointer;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
}

.form-group input[type='text'],
.form-group input[type='file'],
.form-group select {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group img {
  max-width: 150px;
  height: auto;
  margin-top: 10px;
  border-radius: 4px;
}

.modal-actions {
  text-align: right;
}

.submit-btn,
.cancel-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  margin-left: 10px;
}

.submit-btn {
  background-color: #28a745;
}

.submit-btn:hover {
  background-color: #218838;
}

.cancel-btn {
  background-color: #6c757d;
}

.cancel-btn:hover {
  background-color: #5a6268;
}

/* 响应式处理 */
@media (max-width: 768px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    background-color: #fff;
  }

  td {
    border: none;
    position: relative;
    padding-left: 50%;
    text-align: left;
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 15px;
    width: calc(50% - 30px);
    white-space: nowrap;
    font-weight: bold;
  }

  td img {
    max-width: 100px;
    height: auto;
  }

  .modal-content {
    width: 95%;
    padding: 20px;
  }
}
</style>
